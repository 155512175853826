
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { SalesPromiseStatus } from "@/store/modules/activity.store";

  @Component({
    components: {
      GeneralStatusLabel: () => import("@/components/general/status-label.vue"),
    },
  })
  export default class SalesPromiseStatusColumn extends Vue {
    @Prop({ default: SalesPromiseStatus.approved }) status!: SalesPromiseStatus;

    styles: { [key in SalesPromiseStatus]: { borderColor: string; backgroundColor: string; color: string } } = {
      TODO: { borderColor: "#F1F2F7", backgroundColor: "#F1F2F7", color: "#333" }, // Gray
      IN_PROGRESS: { borderColor: "#FFCA54", backgroundColor: "#FFCA54", color: "#fff" }, // Yellow
      REQUESTED: { borderColor: "#FFCA54", backgroundColor: "#FFCA54", color: "#fff" }, // Yellow
      WAITING: { borderColor: "#FF6A5B", backgroundColor: "#FF6A5B", color: "#fff" }, // Red
      APPROVED: { borderColor: "#1BCFA9", backgroundColor: "#1BCFA9", color: "#fff" }, // Green
    };
  }
